.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
    color: #33fff3;
  }
  
  .site-layout-sub-header-background {
    background: #fff;
  }
  
  .site-layout-background {
    background: #fff;

    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
  }
    [data-theme="dark"] .site-layout-sub-header-background {
      background: #141414;
    }

.card-item {
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}